import { Injectable } from "@angular/core";
import { IAuthService, IAuthProvider } from "@otris/ng-core-shared";

@Injectable()
export class AzureADAuthProviderService implements IAzureADAuthProviderService{
	constructor(private _authService: IAuthService) {
		this._authService.registerAuthProvider(this);
	}

	private _isAuthenticated = false;

	get isAuthenticated(): boolean {
		return this._isAuthenticated;
	}

	set isAuthenticated(val: boolean) {
		this._isAuthenticated = val;
	}

	private _payload: object;
	
	get payload(): object {
		return this._payload
	}

	set payload(obj: object) {
		this._payload = obj;
	}

	/*isAuthenticated(): boolean {
		return !!this._msalService.getAccount();
	}*/
}

//@Injectable({ providedIn: AzureADModule, useClass: AzureADAuthProviderService })
@Injectable()
export abstract class IAzureADAuthProviderService implements IAuthProvider  {
	//abstract isAuthenticated(): boolean;
	abstract get isAuthenticated(): boolean;

	abstract set isAuthenticated(val: boolean);

	abstract get payload(): object;

	abstract set payload(obj: object);
}
