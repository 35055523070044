import { Input, ChangeDetectorRef, Directive } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { UIPreviewSpec, UIRootContainerSpec } from '../../model/pd-layout';
import { PDRootContainerComponent } from '../pd-root-container/pd-root-container.component';
import { FormHandlerService } from '../../services/form-handler.service';

@Directive()
export abstract class PDAbstractPreviewComponent extends PDRootContainerComponent {

	@Input() previewSpec: UIPreviewSpec;

	protected get rootContainerSpec(): UIRootContainerSpec {
		return this.previewSpec;
	}

	constructor(router: Router, route: ActivatedRoute, changeDetector: ChangeDetectorRef, formHandler: FormHandlerService) {
		super(router, route, changeDetector, formHandler);
	}

}
