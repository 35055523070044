import { Injectable } from '@angular/core';
import { IFileSystemService } from '@otris/ng-core';
import { saveAs } from '@progress/kendo-file-saver';
import { IKendoFileSystemImplementationService } from './kendo-file-system-implementation.service';
import { Observable } from 'rxjs';

@Injectable()
export class KendoFileSystemService implements IFileSystemService {

	constructor(private fileSystemImplService: IKendoFileSystemImplementationService) {}

	saveFile(data: string, fileName: string): void {
		saveAs(data, fileName);
	}

	loadFile(): Observable<string> {
		return this.fileSystemImplService.loadFile();
	}
}
