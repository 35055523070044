import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MsalBroadcastService, MsalModule, MsalRedirectComponent, MsalService, MSAL_INSTANCE } from "@azure/msal-angular";
import { IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation, LogLevel } from '@azure/msal-browser';


//import { MsalAngularConfiguration, MsalModule, MsalService, MSAL_CONFIG, MSAL_CONFIG_ANGULAR } from "@azure/msal-angular";
import { ServiceLocator } from "@otris/ng-core-shared";
import { AzureADRoutingModule } from "./azure-ad-routing.module";
import { AzureADLoginComponent } from "./components/azure-ad-login/azure-ad-login.component";
//import { Configuration } from "msal";
import { IAzureADConfigService } from "../../services/azure-ad-config.service";
import { AzureADAuthProviderService, IAzureADAuthProviderService } from "./services/azure-ad-auth-provider.service";

/*export function createConfig(): Configuration {
	let msalConfigService: IMsalConfigService = ServiceLocator.injector.get(IMsalConfigService);
	return {
		auth: {
			clientId: msalConfigService.config.clientId,
			authority: msalConfigService.config.authority,
			redirectUri: msalConfigService.config.redirectUri,
		},
		cache: {
			cacheLocation: 'localStorage',
			storeAuthStateInCookie: false, // Set to true for Internet Explorer 11
		}
	};
}*/
//clientId: 'c71941e8-c90c-4a10-8542-97b92a0fb72e',
//authority: 'https://login.microsoftonline.com/e9410288-becf-4933-bb04-35af086638f3',
//redirectUri: 'https://localhost/reporting-module/login/azureAD'


/*export const msalModuleConfigAngular: MsalAngularConfiguration = {
	popUp: true,
	consentScopes: ['user.read', 'openid', 'profile'],
	unprotectedResources: [],
	protectedResourceMap: [
		['https://graph.microsoft.com/v1.0/me', ['user.read']],
	],
	extraQueryParameters: {
		klee2: '4812'
	},
}*/

/*export function msalModuleConfigFactory() {
	let msalConfigService: IAzureADConfigService = ServiceLocator.injector.get(IAzureADConfigService);
	if (!msalConfigService.config) {
		throw new Error('Error in function msalModuleConfigFactory(). No AzureADConfig defined.');
		
	}
	return {
		auth: {
			clientId: msalConfigService.config.clientId,
			authority: msalConfigService.config.authority,
			redirectUri: msalConfigService.config.redirectUri,
			//clientId: msalConfigService.config ? msalConfigService.config.clientId : 'c71941e8-c90c-4a10-8542-97b92a0fb72e',
			//authority: msalConfigService.config ? msalConfigService.config.authority : 'https://login.microsoftonline.com/e9410288-becf-4933-bb04-35af086638f3',
			//redirectUri: msalConfigService.config ? msalConfigService.config.redirectUri : 'https://localhost/reporting-module/login/azureAD'
		},
		cache: {
			cacheLocation: 'localStorage',
			storeAuthStateInCookie: false, // Set to true for Internet Explorer 11
		}
	};
};*/

export function loggerCallback(logLevel: LogLevel, message: string) {
	console.log(message);
}

export function msalInstanceFactory(): IPublicClientApplication {
	let msalConfigService: IAzureADConfigService = ServiceLocator.injector.get(IAzureADConfigService);
	if (!msalConfigService.config) {
		throw new Error('Error in function msalModuleConfigFactory(). No AzureADConfig defined.');
		
	}
	return new PublicClientApplication({
		auth: {
			//   clientId: '6226576d-37e9-49eb-b201-ec1eeb0029b6', // Prod enviroment. Uncomment to use. 
			//clientId: '3fba556e-5d4a-48e3-8e1a-fd57c12cb82e', // PPE testing environment
			//   authority: 'https://login.microsoftonline.com/common', // Prod environment. Uncomment to use.
			//authority: 'https://login.windows-ppe.net/common', // PPE testing environment.
			//redirectUri: '/',

			navigateToLoginRequestUrl: true,
			clientId: msalConfigService.config.clientId,
			authority: msalConfigService.config.authority,
			redirectUri: msalConfigService.config.redirectUri,
			postLogoutRedirectUri: '/',
		},
		cache: {
			cacheLocation: BrowserCacheLocation.LocalStorage,
			storeAuthStateInCookie: false, // set to true for IE 11. Remove this line to use Angular Universal
		},
		system: {
			allowRedirectInIframe: true,
			loggerOptions: {
				loggerCallback,
				logLevel: LogLevel.Info,
				piiLoggingEnabled: false
			}
		}
	});
 }

@NgModule({
	declarations: [
		AzureADLoginComponent,
		//MsalRedirectComponent
	],
	imports: [
		CommonModule,
		AzureADRoutingModule,
		/*MsalModule.forRoot(
			AzureADModule.createConfig(),
			{
				popUp: false, // todo
				consentScopes: ['user.read', 'openid', 'profile'], // todo
				unprotectedResources: [],
				protectedResourceMap: [
					['https://graph.microsoft.com/v1.0/me', ['user.read']], // todo
				],
				extraQueryParameters: {},
			}
		)*/
		MsalModule
	],
	providers: [
		MsalService,
		MsalBroadcastService,
		{
			provide: MSAL_INSTANCE,
			useFactory: msalInstanceFactory
		 },
		//{ provide: MSAL_CONFIG, useFactory: msalModuleConfigFactory },
		//{ provide: MSAL_CONFIG_ANGULAR, useValue: msalModuleConfigAngular },
		{ provide: IAzureADAuthProviderService, useClass: AzureADAuthProviderService }
	]
 })
 export class AzureADModule {
	
	constructor() {
	  console.log('Loaded AzureADModule');
	}
 }
