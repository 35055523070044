import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent} from './components/main/main.component';
import { AuthGuard } from '@otris/privacy-ng-lib';

const routes: Routes = [
	{
		path: 'main',
		component: MainComponent,
		canActivate: [AuthGuard]
		/*children: [
			{ path: '', redirectTo: 'documentList', pathMatch: 'full' },
			{
				path: 'documentList',
				component: DocumentListComponent,
				canActivate: [DocumentListGuard]
			}
		]*/
	}
];


@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }




/*import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PDObjectEditComponent } from '@otris/kendo-ui';
import { IPDObjectResolverData, PDObjectResolver, PDObjectResolverModeET } from '@otris/ng-core';
import { AuthGuard, MainComponent, RouterOutletsET } from '@otris/privacy-ng-lib';
//import { MainFormComponent } from './components/main-form/main-form.component';

const routes: Routes = [
	{
		path: 'main',
		component: MainComponent,
		canActivate: [AuthGuard],
		children: [
			{ path: '', redirectTo: 'verarbeitung', pathMatch: 'full' },
			{
				path: 'verarbeitung',
				component: MainFormComponent
			},
			{
				path: 'createRelationObject/:role/:relationClass/:parentId',
				component: PDObjectEditComponent,
				outlet: RouterOutletsET.Overlay,
				resolve: { pdObjectResolveResult: PDObjectResolver },
				data: {
					resolveData: <IPDObjectResolverData>{ mode: PDObjectResolverModeET.CreateRelationObject}
				}
			}
		]
	}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })], // enableTracing: false
  exports: [RouterModule]
})
export class AppRoutingModule { }*/
