import { Directive, TemplateRef } from '@angular/core';

@Directive({
	selector: '[otrisPDFormHeaderTemplate]'
})
export class PDFormHeaderTemplateDirective {

	get template(): TemplateRef<any> {
		return this._template;
	}

	constructor(private _template: TemplateRef<any>) { }
}
