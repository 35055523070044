import { Injectable } from '@angular/core';
import {
	NotificationService as KendoNotificationService,
	NotificationRef as KendoNotificationRef,
	NotificationSettings as KendoNotificationSettings
} from '@progress/kendo-angular-notification';
import { INotificationService, INotificationSettings, INotificationInstance } from '@otris/ng-core-shared';

@Injectable()
export class NotificationService implements INotificationService {
	constructor(private kendoNotificationService: KendoNotificationService) {
	}

	show(settings: INotificationSettings): INotificationInstance {
		let kendoSettings: KendoNotificationSettings = {
			appendTo: settings.anchor,
			content: settings.content,
			closable: settings.closable,
			type: { style: settings.type, icon: true },
			position: settings.position,
			animation: { type: 'fade', duration: 500 }
		};

		let result = this.kendoNotificationService.show(kendoSettings);
		return <INotificationInstance>{ afterHide$: result.afterHide, hide: () => { result.hide() }};
	}
}