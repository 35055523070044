import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { UIAbstractDirective } from '../directives/ui-abstract.directive';
//import { PDListRowDetailsItemDirective } from '../directives/pd-list-row-details-item.directive';


@Injectable({providedIn: 'root'})
export class GlobalTemplateProviderService {

	private _templateRegistered$: Subject<string> = new Subject();

	get templateRegistered$(): Subject<string> {
		return this._templateRegistered$;
	}

	private _registeredTemplates: Map<string, UIAbstractDirective> = new Map<string, UIAbstractDirective>();

	constructor() { }

	registerTemplate(directive: UIAbstractDirective) {
		if (this._registeredTemplates.has(directive.id)) {
			throw new Error("Tenmplate already registered.");
		}
		this._registeredTemplates.set(directive.id, directive);
		this._templateRegistered$.next(directive.id);
	}

	getTemplate(id: string): UIAbstractDirective | undefined {
		if (this._registeredTemplates.has(id)) {
			return this._registeredTemplates.get(id);
		}
		return undefined;
		//throw new Error(`Tenmplate with id '${id}' not registered.`);
	}
}
