import { Injectable, Inject, Optional, SkipSelf, InjectionToken } from '@angular/core';
import { IPDObjectEditContext, IPDObjectEditContextManager } from './i-pd-object-edit-context';

export const IPDObjectEditContextManagerToken = new InjectionToken<IPDObjectEditContextManager>(
	'IPDObjectEditContextManagerToken',
	{
		providedIn: 'root',
		factory: () => new PDObjectEditContextManager()
	}
);

@Injectable()
class PDObjectEditContextManager implements IPDObjectEditContextManager {

	private _contextStack: IPDObjectEditContext[] = [];

	get currentContext(): IPDObjectEditContext {
		return this._contextStack.length > 0 ? this._contextStack[this._contextStack.length - 1] : undefined;
	}

	switchContext(ctx: IPDObjectEditContext): void {
		this._contextStack.push(ctx);
	}

	releaseCurrentContext(): void {
		if (this._contextStack.length > 0) {
			this._contextStack.splice(this._contextStack.length - 1, 1);
		}
	}
}
