import { ViewContainerRef, InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export const INotificationServiceToken = new InjectionToken<INotificationService>('INotificationServiceToken');

export interface INotificationSettings {
	anchor: ViewContainerRef;
	content: string;
	type: 'none' | 'error' | 'warning' | 'info' | 'success';
	position: { horizontal: 'center' | 'left' | 'right', vertical: 'top' | 'bottom' };
	closable: boolean;
}

export interface INotificationInstance {
	hide(): void;
	afterHide$: Observable<void>;
}

export abstract class INotificationService {
	abstract show(settings: INotificationSettings): INotificationInstance; 
}
