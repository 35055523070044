import 'reflect-metadata';

const JsonPropertyKey = Symbol("JsonProperty");

export function JsonProperty(propName: string) {
	return Reflect.metadata(JsonPropertyKey, propName);
}

export function getJsonProperty(target: any, propertyKey: string) {
	return Reflect.getMetadata(JsonPropertyKey, target, propertyKey);
}



/*export function JsonProperty(propName: string) {
	return function (target: any, key: string) {
		Object.defineProperty(target, propName, {
			get: () => this[key],
			enumerable: true
		});
	}
}*/
