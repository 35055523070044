import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export abstract class IFileSystemService {

	abstract saveFile(data: string, fileName: string): void;

	abstract loadFile(): Observable<string>;
}
