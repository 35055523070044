import { InjectionToken, Injectable } from '@angular/core';

export const ILocalStorageServiceToken = new InjectionToken<ILocalStorageService>(
	'ILocalStorageServiceToken',
	{
		providedIn: 'root',
		factory: () => new LocalStorageService()
	}
);

@Injectable()
export abstract class ILocalStorageService {

	abstract setItem(key: string, value: string): void;

	abstract getItem(key: string): string | undefined;

	abstract hasItem(key: string): boolean;

	abstract removeItem(key: string): void;

	abstract clear(): void;
}

@Injectable()
class LocalStorageService implements ILocalStorageService {
	setItem(key: string, value: string): void {
		localStorage.setItem(key, value);
	}

	getItem(key: string): string | undefined {
		let val = localStorage.getItem(key);
		return val === null ? undefined : val;
	}

	hasItem(key: string): boolean {
		return localStorage.getItem(key) !== null;
	}

	removeItem(key: string): void {
		localStorage.removeItem(key);
	}

	clear(): void {
		localStorage.clear();
	}
}


