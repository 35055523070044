import { AbstractControl } from "@angular/forms";

export function emailValidator(control: AbstractControl): {[key: string]: boolean} | null {
  // https://regex101.com/r/Xlf1Rd/1
  // https://regex101.com/r/Xlf1Rd/2 mit leeren string
  // null string ebenso erlaubt, da sonst bei einem NICHT madatory field eine Eingabe erzwungen wird, durch die regex alleine
  const EMAIL_REGEXP = new RegExp("^$|^[a-zA-Z0-9.!#$%äöüÄÖÜ&’*+\/=?^_`{|}~-]+@[a-zA-Z0-9-äöüÄÖÜ]+(?:\.[a-zA-Z0-9-äöüÄÖÜ]+)*$");

  // undefined fällt durch den Test durch?
  return (EMAIL_REGEXP.test((control.value)) || !!!control.value)? null : { validateEmail: true };
}