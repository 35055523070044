import { DomSanitizer } from '@angular/platform-browser';
import { PipeTransform, Pipe } from "@angular/core";

@Pipe({ name: 'safeStyle' })
export class SafeStylePipe implements PipeTransform {
	constructor(private sanitized: DomSanitizer) { }
	transform(value) {
		return this.sanitized.bypassSecurityTrustStyle(value);
	}
}
