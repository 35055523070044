import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

export interface IFileLoadHandler {
	loadFile(): void;
	fileLoaded$: Observable<string>;
}

@Injectable()
export class KendoFileSystemImplementationService implements IKendoFileSystemImplementationService {

	loadFile(): Observable<string> {
		if (!this._fileLoadHandler) {
			throw new Error('FileLoadHandler not specified.')
		}
		this._fileLoadHandler.loadFile();
		return this._fileLoadHandler.fileLoaded$.pipe(
			take(1)
		);
	}

	private _fileLoadHandler: IFileLoadHandler;

	get fileLoadHandler(): IFileLoadHandler {
		return this._fileLoadHandler;
	}

	set fileLoadHandler(handler: IFileLoadHandler) {
		if (this._fileLoadHandler) {
			throw new Error('FileLoadHandler already specified.')
		}
		this._fileLoadHandler = handler;
	}
}

@Injectable({providedIn: 'root', useClass: KendoFileSystemImplementationService })
export abstract class IKendoFileSystemImplementationService {

	abstract loadFile(): Observable<string>;

	abstract get fileLoadHandler(): IFileLoadHandler;

	abstract set fileLoadHandler(handler: IFileLoadHandler);
}
