import { Component, HostListener } from "@angular/core";
import { IAuthService } from '@otris/ng-core-shared';

@Component({
	selector: 'giz-root',
	template: `
		<router-outlet></router-outlet>
		<privacy-core-module-info-template></privacy-core-module-info-template>
		<otris-kendo-dialog-container></otris-kendo-dialog-container>
		<otris-kendo-localization-handler></otris-kendo-localization-handler>
	`,
	styles: [],
})
export class AppComponent {
	@HostListener('window:beforeunload', ['$event'])
	beforeunloadHandler(event) {
		console.log("AppComponent.beforeunloadHandler()");
		this.authService.logout().subscribe();
	}

	constructor(private authService: IAuthService) {
	}
}
