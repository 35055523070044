import { Directive, TemplateRef, Input } from '@angular/core';

@Directive({
	selector: '[otrisUITemplate]'
})
export class UITemplateDirective {
	@Input('otrisUITemplate') templateName: string;

	get template(): TemplateRef<any> {
		return this._template;
	}

	constructor(private _template: TemplateRef<any>) { }
}
