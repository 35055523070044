/*import pkgJson from '../../../../package.json';
import angularCorePkgJson from '@angular/core/package.json';
import otrisPrivacyNgLibPkgJson from '@otris/privacy-ng-lib/package.json';
import otrisJafWebPkgJson from '@otris/jaf-web/package.json';
import otrisNgCoreSharedPkgJson from '@otris/ng-core-shared/package.json';
import otrisNgCorePkgJson from '@otris/ng-core/package.json';
import otrisKendoUIPkgJson from '@otris/kendo-ui/package.json';
*/
export const environment = {
	/*appVersion: pkgJson.version,
	privacyNgLibVersion: otrisPrivacyNgLibPkgJson.version,
	otrisJafWebVersion: otrisJafWebPkgJson.version,
	otrisNgCoreSharedVersion: otrisNgCoreSharedPkgJson.version,
	otrisNgCoreVersion: otrisNgCorePkgJson.version,
	otrisKendoUIVersion: otrisKendoUIPkgJson.version,
	angularCoreVersion: angularCorePkgJson.version,*/
	production: true
};
