import { Injectable } from "@angular/core";

export type AzureADConfig = {
	clientId: string;
	authority: string,
	redirectUri: string
}

@Injectable()
export class AzureADConfigService implements IAzureADConfigService{

	private _config: AzureADConfig;

	get config(): AzureADConfig | undefined {
		return this._config;
	}

	set config(cfg: AzureADConfig) {
		this._config = cfg;
	}
}

@Injectable({ providedIn: 'root', useClass: AzureADConfigService })
export abstract class IAzureADConfigService {
	abstract get config(): AzureADConfig | undefined;

	abstract set config(cfg: AzureADConfig);
}
