export class Version {

	private _major: number = 0;

	get major(): number {
		return this._major;
	}

	private _minor: number = 0;

	get minor(): number {
		return this._minor;
	}

	private _patch: number = 0;

	get patch(): number {
		return this._patch;
	}

	private _preRelease: string;

	get preRelease(): string | undefined {
		return this._preRelease;
	}

	constructor(version: string) {
		const errMsg = `Invalid version string '${version}'`;
		if (!version) {
			throw new Error(errMsg);
		}
		let preReleasePos = version.indexOf('-');
		if (preReleasePos === version.length-1) {
			throw new Error(errMsg);
		}
		let mainVersion = preReleasePos >= 0 ? version.substring(0, preReleasePos) : version;
		if (preReleasePos >= 0) {
			this._preRelease = version.substr(preReleasePos + 1);
		}
		
		let parts = mainVersion.split('.');
		if (parts.length < 1 || parts.length > 4) {
			throw new Error(errMsg);
		}
		parts.forEach((v, i) => {
			let n = Number.parseInt(v);
			if (Number.isNaN(n)) {
				throw new Error(errMsg);
			}
			switch (i) {
				case 0:
					this._major = n;
					break;
				case 1:
					this._minor = n;
					break;
				case 2:
					this._patch = n;
					break;
			}
		})
	}

	static compare(v1: Version, v2: Version) {
		if (v1.major != v2.major) {
			return v1.major > v2.major ? 1 : -1;
		}
		if (v1.minor != v2.minor) {
			return v1.minor > v2.minor ? 1 : -1;
		}
		if (v1.patch != v2.patch) {
			return v1.patch > v2.patch ? 1 : -1;
		}
		if (v1.preRelease === v2.preRelease) {
			return 0;
		}
		if (!v1.preRelease && v2.preRelease) {
			return 1;
		}
		if (v1.preRelease && !v2.preRelease) {
			return -1;
		}
		return v1.preRelease > v2.preRelease ? 1 : -1;
	}

	toString(): string {
		let v = this._major.toString() + '.' + this._minor.toString() + '.' + this._patch.toString();
		if (this.preRelease) {
			v += `-${this.preRelease}`;
		}
		return v;
	}
}